import { Title } from 'components/Typography';
import { getApiLocale } from 'lib/locale';
import { ProductListingModel } from 'models/productListing/types';
import { FC } from 'react';
import styled, { css } from 'styled-components';

const SwatchRow = styled.ul`
  display: flex;
  align-items: center;
  margin: 0;
  margin-top: 16px;
  padding: 0;
  height: 16px;
  list-style: none;
`;

const Swatch = styled.li<{ swatch: string }>(
  ({ swatch }) => css`
    box-shadow: inset 0px 0px 1px 1px rgba(0, 0, 0, 0.1);
    width: 12px;
    height: 12px;
    border-radius: 50%;
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: 8px;
    overflow: hidden;
    background-size: cover;
    background-image: url('${swatch}?auto=compress,format&max-w=12&max-h=12&fit=fill');
  `
);

export function mapGrapheneSwatches(
  product: Graphene.Product,
  locale: string
): Record<string, string> {
  const { store } = getApiLocale(locale);
  const warehouse = store === 'us-store' ? 'us' : 'uk';
  const swatches: Record<string, string> = {};

  const colourways = {
    ...product.item.features?.swatch?.options,
    ...product.item.features?.[`${warehouse}swatches`]?.options,
  };

  for (const colourway in colourways) {
    swatches[`${process.env.NEXT_PUBLIC_IMGIX_URL}/swatches/${colourway}.jpg`] =
      colourways[colourway].definition.titles.default;
  }

  return swatches;
}

const Swatches: FC<Pick<Partial<ProductListingModel>, 'swatches'>> = ({
  swatches: swatchMap = {},
}) => {
  const swatches = Object.entries(swatchMap);

  const extraSwatches = Math.max(0, swatches.length - 4);

  // Do not show swatches for single colourway items.
  if (swatches.length <= 1) {
    return null;
  }

  return (
    <SwatchRow>
      {swatches.slice(0, 4).map(([key, label]) => (
        <Swatch key={key} title={label} swatch={key} />
      ))}
      {extraSwatches ? (
        <Title as="li" variant="1" size="2XS">
          {`+${extraSwatches}`}
        </Title>
      ) : null}
    </SwatchRow>
  );
};

export default Swatches;
